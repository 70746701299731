<template>
	<DashboardTemplate>
			<el-tabs type="border-card">
  				<el-tab-pane label="AC Adjust">
  					<h2>Account Adjust</h2>
  				</el-tab-pane>
  				<el-tab-pane label="Inventory Adjust">
  					<h2>Inventory Adjust</h2>
  					<el-tabs type="border-card">
  						<el-tab-pane v-for="data in room.groups" :key="data.name" :label="data.name">
	  						<investory />
	  					</el-tab-pane>
	  				</el-tabs>
	  			</el-tab-pane>
  			</el-tabs>
	</DashboardTemplate>
</template>
<script>

import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'	
import DashboardTemplate from'@/template/DashboardTemplate'
import investory from'@/components/inventory/InventoryBalance'
	export default{
		components:{
			DashboardTemplate,
			investory
		},
		computed:{
			room(){
				return this.$store.state.room
			},
			user(){
				return this.$store.state.user
			}
		},
		data(){
			return{

			}
		}
	}
</script>